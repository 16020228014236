import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/luth-surgery-oesophagus/1.jpeg';
import blogd2 from '../../assets/images/2024/luth-surgery-oesophagus/2.jpeg';
import blogd3 from '../../assets/images/2024/luth-surgery-oesophagus/3.jpeg';
import blogd4 from '../../assets/images/2024/luth-surgery-oesophagus/4.jpeg';
import blogd5 from '../../assets/images/2024/luth-surgery-oesophagus/5.jpeg';
import blogd6 from '../../assets/images/2024/luth-surgery-oesophagus/6.jpeg';
import blogd7 from '../../assets/images/2024/luth-surgery-oesophagus/7.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  LUTH PERFORMS SURGERY ON 13-DAY OLD TO FREE OESOPHAGUS
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Doctors at the Lagos University Teaching Hospital, LUTH, have
                  successfully performed a Thoracoscopic Primary Repair of
                  Oesophageal Atresia with tracheosophageal Fistula on a 13-day
                  old baby. <br />
                  The Chief Medical Director at LUTH, Prof Wasiu Adeyemo said
                  the surgery through the oesophagus of the neonate entailed
                  minimal access surgery on the baby, the first of its kind in
                  any public tertiary hospital in Nigeria.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Renovation of Ward C
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  “Babies with such conditions are unable to feed, they choke
                  when fed because the tube that carries food to the stomach is
                  blocked. So, they regurgitate, the breast milk comes from
                  their nose and their mouth; they can take it into their chest
                  and it now becomes a problem to them. So that's first thing
                  and you see them bringing out saliva and it is foaming. Once
                  you see those symptoms, most likely that child has a blockage.
                  <br />
                  “This surgery would usually have been done as open thoracotomy
                  with ligation of fistula and end-to-end anastomosis of
                  oesophagus. This will leave the neonate with a large chest
                  wound and turbulent post-operative recovery period. But recent
                  advances in the developed nations utilize minimal access
                  surgery (Thoracoscopic repair) which has the advantage of
                  minimal tissue injury and therefore reduced metabolic response
                  to trauma and ultimately reduced surgery associated morbidity
                  and better outcome. The baby recovery after surgery was
                  uneventful. The baby will be discharged from the hospital
                  tomorrow,” he explained.
                  <br />
                  LUTH is one of the two public hospitals in Nigeria with solely
                  dedicated paediatric laparoscopic towers and laparoscopic
                  instruments. This is made possible due to increase in funding
                  by the Federal Government.
                  <br />
                  “The management of LUTH expresses our appreciation to the team
                  of surgeons, anaesthetists, nurse and other supporting staff
                  that made this feat possible. Our appreciation also goes to Dr
                  Igwe of EverCare Specialist Hospital for his readiness to
                  share his knowledge and expertise with us in this particular
                  case. LUTH is poised to continue to partner with all Nigerians
                  (home and abroad) with expertise in all specialties of
                  medicine for the benefits of all Nigerians,” said Prof
                  Adeyemo.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                Prof. Wasiu ‘Lanre Adeyemo, FAS(The Chief Medical Director)
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Lead surgeon, Dr. Felix Alakaloko said babies who require this
                  kind of surgery cannot eat because their oesophagus, which is
                  the pipe that carries food to the stomach, is blocked at
                  birth. <br />
                  “Something must be done to reconnect that blocked tube so that
                  they can eat. And now that is where we come in and they come
                  to us and we have to operate them. Now operating them is very
                  difficult. Because you remember this tube that carries food is
                  in the chest, that means you are going to work on the chest of
                  a new born child to go and reconnect the tube.
                  <br></br>
                  "The space is very small. So, when you have to cut open, you
                  endanger the patient as well as trying to help the patient
                  because we are going to make the patient go through a lot of
                  trauma. Sometimes the patient cannot be helped immediately, so
                  you have to divert the pipe and then find a way to feed them
                  using tubes which is very, very demanding.
                  <br></br>
                  “But with the increased funding for the teaching hospital, we
                  have the equipment and facilities that are cutting edge which
                  are the same as obtained in the international community in
                  America and Canada and even in the UK. The equipment and the
                  human resources are available. And we are able to treat this
                  patient under minimal access. They don't have so much trauma
                  on them,” said Dr Alakaloko, a paediatric surgeon.
                  <br></br>
                  The specialised surgery which costs about N10 million abroad
                  and N6 million at private hospitals, was highly subsidised by
                  LUTH. Management said this particular case cost just N300,000.
                  <br></br>
                  “We are not oblivious of the fact many patients, or parents
                  are indigents and poor. We thank the Federal Ministry of
                  Health and Social Welfare and the two ministers for their
                  passion in ensuring increased funding for tertiary health
                  institutions,” said Prof Adeyemo.
                  
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                 
                </span>
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  On his part, Minister of State for Health, Mr. Ekumankama
                  Nkama, thanked the initiators and managers of the project for
                  a proper execution, adding that the ward, which was built
                  about 60 years ago had not undergone any major renovation.
                  <br></br>
                  “We hope for more from your organisation and any other similar
                  organisations. I am delighted that NLNG has made efforts to
                  ensure good healthcare delivery. Qualitative healthcare cannot
                  be achieved by the government alone, we solicit assistance
                  from multinational companies,” he said.
                  <br></br>
                  Also speaking, Acting Chief Medical Director, LUTH, Professor
                  Wasiu Adeyemo, appealed to well-meaning Nigerians, corporate
                  bodies and multinational companies to extend their corporate
                  social responsibilities to the institution.
                  <br></br>
                  “On behalf of the board of management, staff and students of
                  LUTH, and Nigerians in general, I would like to publicly
                  appreciate this singular effort by Nigeria LNG to contribute
                  their own quota as part of their CSR to improve the facilities
                  in our hospital. I am aware this project is among the 6
                  projects in Phase 1 of their efforts. I want to plead that
                  LUTH is remembered when subsequent phases of your efforts in
                  improving facilities in our hospital begin. We shall be
                  forever grateful,” he concluded.
                </p> */}
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd8} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd9} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
